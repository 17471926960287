import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { UserService } from '../../core/services/data/user.service';
import { ModalService } from '../../core/services/modal.service';
import { InputControlService } from '../../core/services/input-control.service';
import { FrontService } from '../../core/services/front.service';
import { AgentService } from '../../core/services/agent.service';
import { OrderService } from '../../core/services/data/order.service';

import { ContactForm } from './contact-form';

@Component({
	selector: 'app-contact-form',
	templateUrl: './contact-form.component.html',
	styleUrl: './contact-form.component.scss',
	encapsulation: ViewEncapsulation.Emulated,
})

export class ContactFormComponent implements OnInit, OnDestroy {
	@Input() maskClose;

	@Input() visible;

	public modalId = 'contactFormModal';

	public formComponents = new ContactForm();

	public form: UntypedFormGroup;

	public author$;

	public author;

	public loading = false;

	public root;

	public errorText;

	public successText;

	public sent = false;

	constructor(
		private ms: ModalService,
		private us: UserService,
		private ics: InputControlService,
		private fs: FrontService,
		private router: Router,
		private as: AgentService,
		private os: OrderService,
	) { }

	ngOnInit() {
		this.root = window.location.origin;
		this.author$ = this.us.author$.subscribe(
			(data: any) => {
				this.author = data;

				this.formComponents.email.value = this.author.username;
				this.formComponents.name.value = `${this.author.firstName} ${this.author.lastName}`;
				this.form = InputControlService.toFormGroup(this.formComponents);
			},
		);

		this.form = InputControlService.toFormGroup(this.formComponents);
	}

	ngOnDestroy() {
		this.author$?.unsubscribe();
	}

	close() {
		this.ms.close(this.modalId);
	}

	async onSubmit(event) {
		event.preventDefault();
		this.loading = true;

		const errors = this.ics.checkFormValid(this.form, this.formComponents);
		if (errors.hasErrors) {
			this.errorText = ['Oops', 'Looks like there was a problem with the information you submitted.'];
			this.ms.open('error-modal');
			this.loading = false;
		} else {
			const body = {
				authorEmail: this.form.value.email,
				authorName: this.form.value.name,
				message: this.form.value.message,
				dashboardUrl: this.router.url,
				browser: this.as.getBrowserInfo(),
				memberStatus: this.us.getMemberRoleName(),
				orderId: this.os.orderId ? this.os.orderId : null,
			};

			this.fs.sendContactForm(body).then(() => {
				this.sent = true;
				this.loading = false;
			});
		}
	}

	joinFacebook() {
		(window as Window).open('https://www.facebook.com/groups/538674334318179', '_blank');
	}

	goToBlog() {
		(window as any).open('https://www.writtenwordmedia.com/learn/', '_blank');
	}

	goToSupport() {
		(window as any).open('https://support.writtenwordmedia.com/category/hvmn7oxmjs-written-word-media', '_blank');
	}
}
