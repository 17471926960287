import { Injectable } from '@angular/core';
import to from 'await-to-js';
import { ServerConnectionService } from './server-connection.service';
import { CoreModule } from '../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class AgentService {
	constructor(
		private scs: ServerConnectionService,
	) { }

	getBrowserInfo(): string {
		const { userAgent } = window.navigator;
		let browserName = 'Unknown';

		if (userAgent.indexOf('Firefox') > -1) {
			browserName = 'Mozilla Firefox';
		} else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
			browserName = 'Opera';
		} else if (userAgent.indexOf('Trident') > -1) {
			browserName = 'Microsoft Internet Explorer';
		} else if (userAgent.indexOf('Edge') > -1) {
			browserName = 'Microsoft Edge';
		} else if (userAgent.indexOf('Chrome') > -1) {
			browserName = 'Google Chrome';
		} else if (userAgent.indexOf('Safari') > -1) {
			browserName = 'Apple Safari';
		}

		return browserName;
	}

	async getCurrentLocation() {
		const [err, position] = await to(new Promise<GeolocationPosition>((resolve, reject) => {
			if (navigator.geolocation) {
			  navigator.geolocation.getCurrentPosition(resolve, reject);
			} else {
			  reject(new Error('Geolocation is not supported by this browser.'));
			}
		}));

		if (err) {
			return '';
		}

		const { latitude, longitude } = position.coords;

		return this.isUSCoordinates(latitude, longitude) ? 'US' : 'Not US';
	}

	isUSCoordinates(latitude, longitude) {
		const US_LAT_MIN = 24.396308;
		const US_LAT_MAX = 49.384358;
		const US_LON_MIN = -125.0;
		const US_LON_MAX = -66.93457;

		if (latitude >= US_LAT_MIN && latitude <= US_LAT_MAX && longitude >= US_LON_MIN && longitude <= US_LON_MAX) {
			return true;
		}

		return false;
	}
}
