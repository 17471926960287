<section>
  <div class="content" *ngIf="author" [ngSwitch]="state">
    <div class="logo">
      <img src="https://storage.googleapis.com/wwm-static-content/dark-logo.png" />
    </div>
    <div *ngSwitchCase="'waiting'">
      <div class="spinner-box">
        <div class="spinner-text">Please wait...</div>
        <div class="spinner"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
    <div *ngSwitchCase="'new'">
      <h3>Almost Done!</h3>
      <p>An email has been sent to {{author.username}}. Please click the link in the email to confirm your account.</p>
    </div>
    <div *ngSwitchCase="'login'">
      <p>Hi {{author.firstName}},<br/>This account has not been confirmed yet. Please check your email for a confirmation from us and 
        click the link inside.</p>
      <p>If you need us to send the email again, please click <span class="button" (click)="resendEmail()">here</span></p>
    </div>
    <div *ngSwitchCase="'resent'">
      <p>
        Hi {{author.firstName}},<br/>An email has been sent to <span class="button">{{author.username}}</span>. Please click the link in 
        the email to confirm your account.
        Please note that any earlier confirmation emails will now be invalid.
      </p>
    </div>
    <div *ngSwitchCase="'invalid'">
      Hi {{author.firstName}},<br/>Unfortunately the confirmation code for <span class="button">{{author.username}}</span> was incorrect. Please click
      <span class="button" (click)="resendEmail()">here</span> to resend your confirmation email.
    </div>
    <div *ngSwitchCase="'old'">
      <p>
        Hi {{author.firstName}},<br/>Unfortunately the confirmation code for <span class="button">{{author.username}}</span> has expired. Please click
        <span class="button" (click)="resendEmail()">here</span> to resend your confirmation email.
      </p>
    </div>
    <div *ngSwitchCase="'send-error'">
      <p>There was an error trying to send the confirmation email.</p>
    </div>
    <div *ngSwitchCase="'confirmed'">
      <h2>Success!</h2>
      <p>Your account has been created. We will now redirect you to your home page.</p>
    </div>
  </div>
  <div class="spacer"></div>
</section>
