import _ from 'lodash';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';

import * as moment from 'moment';

import { Router } from '@angular/router';
import { CoreModule } from '../../core.module';

import { SitesService } from './sites.service';
import { SiteAdvertsService } from './site-adverts.service';
import { FeaturedBooksService } from './featured-books.service';
import { UrlQueryService } from '../url-query.service';

import { ISite, ISiteAdvert, IUser } from '../../../types/datatypes';
import { IPopulatedFeature } from '../../../types/populated';
import { IPurchasePathDetails } from '../../../types/local';
import { PopulatedFeaturedBookAPIResponse } from '../../../types/responses';
import { BooksService } from './books.service';
import { UserService } from './user.service';
import { LogsService } from '../logs.service';
import { OrderService } from './order.service';

const defaultSteps = [
	{ step: 1, label: 'Pick a Site', active: false, activeLabel: '', path: '', queryParams: {} },
	{ step: 2, label: 'Pick Your Promo', active: false, activeLabel: '', path: '', queryParams: {} },
	{ step: 3, label: 'Pick a Date', active: false, activeLabel: '', path: '', queryParams: {} },
	{ step: 4, label: 'Book Info', active: false, activeLabel: '', path: '', queryParams: {} },
	{ step: 5, label: 'Payment', active: false, activeLabel: '', path: '', queryParams: {} },
];

@Injectable({
	providedIn: CoreModule,
})
export class PurchaseProcessService {
	public steps = _.cloneDeep(defaultSteps);

	public entryway = 'standard';

	public currentStep = 1;

	public isCheckout = false;

	public siteSlug: string;

	public site: ISite;

	public authorLink: string;

	public siteAdvertSlug: string;

	public siteAdvert: ISiteAdvert;

	public featureId: string;

	public feature: IPopulatedFeature;

	public pubDate: string;

	public friendlyPubDate: string;

	public loading = false;

	public isEdit = false;

	public referrer = null;

	public details: IPurchasePathDetails = {
		feature: {},
		book: {},
	};

	private _loading = new Subject<boolean>();

	private _user;

	private author;

	private $author;

	constructor(
		private siteService: SitesService,
		private siteAdvertsService: SiteAdvertsService,
		private featuredBooksService: FeaturedBooksService,
		private urlQueryService: UrlQueryService,
		private booksService: BooksService,
		private router: Router,
		private userService: UserService,
		private logService: LogsService,
		private orderService: OrderService,
	) {
		this.$author = this.userService.author$.subscribe((author: IUser) => {
			this.author = author;
		});
	}

	get loading$() {
		return this._loading.asObservable();
	}

	get role() {
		return this._user?.memberRole?.roleName;
	}

	get user() {
		return this._user;
	}

	setDetails(details: IPurchasePathDetails, entryway) {
		this.details = details;
		if (entryway) {
			this.entryway = entryway;
		}
	}

	getDetails() {
		return this.details;
	}

	clearDetails() {
		this.details = {
			feature: {},
			book: {},
		};
	}

	setUser(user) {
		this._user = user;
	}

	activateStep({ step, label, path, queryParams }) {
		const _step = this.steps.find((item) => item.step === step);

		_step.active = true;
		_step.activeLabel = label;
		_step.path = path;
		_step.queryParams = queryParams;
		this.currentStep = step + 1;
	}

	resetSteps() {
		this.steps = _.cloneDeep(defaultSteps);
		this.currentStep = 1;
	}

	reset() {
		this.steps = _.cloneDeep(defaultSteps);
		this.siteSlug = null;
		this.site = null;
		this.siteAdvertSlug = null;
		this.siteAdvert = null;
		if (this.isEdit) {
			return;
		}
		this.featureId = null;
		this.feature = null;
	}

	setAuthorLink(authorLink) {
		this.authorLink = authorLink;
	}

	clearEdit() {
		this.isEdit = false;
		this.urlQueryService.save({ feature: null });
	}

	setPaymentAmount(paymentAmount) {
		this.feature.paymentAmount = paymentAmount;
	}

	setState({ siteSlug, siteAdvertSlug, pubDate, featuredBookId }, isCheckout = false, isExperiment = false) {
		// If we are in edit mode. Make sure the URL reflects that.
		if (this.isEdit && this.featureId) {
			this.urlQueryService.save({ feature: this.featureId });
		}
		// Process Feature from the URL.
		this.processUrlParams();
		this.loading = true;
		this._loading.next(this.loading);
		this.siteSlug = siteSlug;
		this.siteAdvertSlug = siteAdvertSlug;
		const _pubDate = pubDate === 'checkout' ? null : pubDate;
		this.setPubDate(_pubDate || this.feature?.pubDate);
		this.featureId = featuredBookId || this.featureId;
		this.isCheckout = isCheckout;

		// Unset isEdit if we're passed the step 4
		if (this.isCheckout || featuredBookId) {
			this.clearEdit();
		}

		this.evaluateState();

		of(null).pipe(concatMap(() => this.getSite()))
			.pipe(concatMap((site: ISite) => {
				if (site) this.site = site;
				return this.getSiteAdvert();
			})).pipe(concatMap((siteAdvert: ISiteAdvert) => {
				if (siteAdvert) this.siteAdvert = siteAdvert;
				return this.getFeaturedBook();
			}))
			.subscribe((result: PopulatedFeaturedBookAPIResponse) => {
				if (result && result.success) {
					this.feature = result.featuredBook;
					if (!this.pubDate) {
						this.setPubDate(this.feature.pubDate);
					}
				}
				// If we already have the featured book, then result is null. This could be confusing.
				if (result && !result.success && this.isEdit) {
					this.clearEdit();
					this.featureId = null;
					this.feature = null;
				}

				if (this.isEdit && result?.featuredBook && !this.isEditable(result.featuredBook)) {
					this.clearEdit();
					this.featureId = null;
					this.feature = null;

					this.router.navigate(['/features']).then();
					return;
				}

				if (this.isEdit) {
					this.setDetails({
						feature: {
							_site: (this.feature._site?._id || this.feature._site) as string,
							_siteAdvert: (this.feature._siteAdvert?._id || this.feature._siteAdvert) as string,
							paymentAmount: this.feature.paymentAmount,
							bookPriceDuringPromo: this.feature.bookPriceDuringPromo,
							purchaserInfo: this.feature.purchaserInfo,
							whereAvailable: this.feature._book.whereAvailable,
						},
						book: {
							ASIN: this.feature._book.ASIN || this.feature.ASIN,
							whereAvailable: this.feature._book.whereAvailable,
							title: this.feature._book.title,
							description: this.feature._book.description,
							bookLength: this.feature._book.bookLength,
							nameOfSeries: this.feature._book.nameOfSeries,
							bookNumber: this.feature._book.bookNumber,
							numberOfBooksInSeries: this.feature._book.numberOfBooksInSeries,
							threesomesOrRomanticScenes: this.feature._book.threesomesOrRomanticScenes,
							endingOfBook: this.feature._book.endingOfBook,
							cheatingOrInfidelity: this.feature._book.cheatingOrInfidelity,
							commonThemes: this.feature._book.commonThemes,
							author: this.feature._book.author,
							amazonImageUrl: this.feature._book.amazonImageUrl,
							amazonLink: this.feature._book.amazonLink,
							link: this.feature._book.link,
						},
					}, this.entryway);
				}
				this.loading = false;
				this._loading.next(this.loading);
				this.processState(isExperiment);
				this.logProgress(isExperiment);
			});
	}

	isEditable(feature: IPopulatedFeature) {
		if (this.author?._id) {
			return feature._order?.status === 'incomplete' && feature._owner === this.author?._id;
		}

		return feature._order?.status === 'incomplete';
	}

	processUrlParams() {
		const { feature, referrer } = this.urlQueryService.load();

		if (feature) {
			this.featureId = feature;
			this.isEdit = true;
		}

		if (referrer) {
			this.referrer = referrer;
		}
	}

	edit(feature: IPopulatedFeature) {
		this.isEdit = true;
		this.featureId = feature._id;
		this.feature = feature;
	}

	evaluateState() {
		if (this.site && !this.siteSlug) {
			this.site = null;
		}

		if (this.siteAdvert && !this.siteAdvertSlug) {
			this.siteAdvert = null;
		}

		if (this.feature && !this.featureId) {
			this.feature = null;
		}

		if (!this.pubDate) {
			this.friendlyPubDate = null;
		}
	}

	setPubDate(date) {
		this.pubDate = date;
		this.friendlyPubDate = moment(date, 'YYYYMMDD').format('ddd, MMM DD, YYYY');
	}

	getSite() {
		return of(null).pipe(
			switchMap(() => {
				if (this.siteSlug && this.siteSlug !== this.site?.seoSlug) {
					return this.siteService.getSiteBySlug(this.siteSlug);
				}
				return of(null);
			}),
		);
	}

	getSiteAdvert() {
		return of(null).pipe(
			switchMap(() => {
				if (this.siteAdvertSlug && this.siteAdvertSlug !== this.siteAdvert?.seoSlug) {
					return this.siteAdvertsService.getSiteAdvertBySlug(this.siteAdvertSlug);
				}
				return of(null);
			}),
		);
	}

	getFeaturedBook() {
		return of(null).pipe(
			switchMap(() => {
				if (this.featureId && this.feature?._id !== this.featureId) {
					return this.featuredBooksService.fetchFullFeaturedBookById(this.featureId);
				}
				return of(null);
			}),
		);
	}

	logProgress(isExperiment) {
		this.logService.postLog({
			type: 'purchase-process',
			message: JSON.stringify({
				order: this.orderService.orderId,
				author: this.author?._id || 'N/A',
				step: this.currentStep,
				site: this.siteSlug || 'N/A',
				siteAdvert: this.siteAdvertSlug || 'N/A',
				pubDate: this.pubDate || 'N/A',
				isEdit: this.isEdit,
				isCheckout: this.isCheckout,
				isExperiment,
				entryway: this.entryway,
			}),
		});
	}

	processState(isExperiment) {
		const featureLink = isExperiment ? 'features2' : 'features';

		this.resetSteps();
		const { siteSlug, siteAdvertSlug, pubDate, authorLink, friendlyPubDate, featureId } = this;

		const _authorPath = authorLink ? `/author-dashboard/${authorLink}` : '';

		const editFeatureQuery = this.featureId ? { feature: this.featureId } : {};

		if (!siteSlug) {
			return;
		}

		this.activateStep({
			step: 1,
			label: this.site.name,
			path: `${_authorPath}/${featureLink}${editFeatureQuery}`,
			queryParams: editFeatureQuery,
		});

		if (!siteAdvertSlug) {
			return;
		}

		this.activateStep({
			step: 2,
			label: this.siteAdvert.name,
			path: `${_authorPath}/${featureLink}/${siteSlug}`,
			queryParams: editFeatureQuery,
		});

		if (!pubDate) {
			return;
		}

		this.activateStep({
			step: 3,
			label: friendlyPubDate,
			path: `${_authorPath}/${featureLink}/${siteSlug}/${siteAdvertSlug}`,
			queryParams: editFeatureQuery,
		});

		if (!featureId || this.isEdit) {
			return;
		}

		this.activateStep({
			step: 4,
			label: this.feature._book.title,
			path: `${_authorPath}/${featureLink}/${siteSlug}/${siteAdvertSlug}/${pubDate}`,
			queryParams: editFeatureQuery,
		});
	}
}
