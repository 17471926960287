import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { UserService } from './data/user.service';
import { SitesService } from './data/sites.service';
import { CoreModule } from '../core.module';

import { AnalyticsEcommerce } from '../../types/analytics-ecommerce.interface';
import { AnalyticsEvent } from '../../types/analytics-event.interface';
import { UAAnalyticsEvent } from '../../types/ua-analytics-event.interface';
import { ExperimentsService } from './experiments.service';

declare const mixpanel: any;

// GA4 Definitions https://docs.google.com/spreadsheets/d/1j6JrIQBpdfvJ2FqdI4UpXxfTk-MMqEz2zI9BbMqdPIg/edit#gid=1137949244
// For Debugging: https://github.com/WrittenWordMedia/wwm-docs/blob/master/guides/GA4_DEBUG.md

interface customWindow extends Window {
	dataLayer?: any;
}
declare const window: customWindow;

@Injectable({
	providedIn: CoreModule,
})
export class AnalyticsService {
	private internalSource;

	private _purchaseData;

	private _referrer;

	private user;

	private variant = 'control';

	constructor(
		private us: UserService,
		private sitesService: SitesService,
		private es: ExperimentsService,
	) {
		this.us.fetchUser().then((data: any) => {
			this.user = data;
		}, () => {
			this.user = {};
		});
	}

	/**
   * Event Tracking function used across the author app.
   * Records events to the data layer in Google Tag Manager
   * @param {string} eventName
   * @param {object} options additional variables to be recorded with an event
   */
	trackGTMEvent(eventName: string, options: AnalyticsEvent | UAAnalyticsEvent = {}) {
		if (window.dataLayer && typeof window.dataLayer !== 'undefined') {
			window.dataLayer.push({ event: eventName, ...options });
		} else {
			window.dataLayer = [{ event: eventName, ...options }];
		}
	}

	_ecommEvent(options: AnalyticsEvent) {
		const event = 'ecommEvent';
		const variant = this.es.getVariant();
		const experimentID = variant === 'control' ? '003_Membership_in_Cart' : '004_PromoStacks';
		const _options = {
			...options,
			experimental: variant !== 'control',
			experimentID,
		};

		this.trackGTMEvent(event, _options);
	}

	homepageTracking(options) {
		const event = 'member_action';
		const _options = {
			event_name: 'dashboard_click',
			...options,
		};

		this.trackGTMEvent(event, _options);
	}

	_userEvent(options: AnalyticsEvent) {
		const event = 'userEvent';
		this.trackGTMEvent(event, options);
	}

	trackSubmitBook(brand: string) {
		const eventName = 'submit_book_information';
		const event: AnalyticsEvent = { event_name: eventName, wmm_brand: this._slugify(brand) };
		this._ecommEvent(event);
	}

	trackBeginCheckout(value, items: AnalyticsEcommerce[]) {
		const eventName = 'begin_checkout';
		const event: AnalyticsEvent = { event_name: eventName, value, ecommerce: { items } };
		this._ecommEvent(event);
	}

	trackAddCoupon(coupon: string, brand: string) {
		const eventName = 'add_coupon';
		const event: AnalyticsEvent = { event_name: eventName, wmm_brand: brand, events: { coupon } };
		this._ecommEvent(event);
	}

	trackAddToCart2(value, items: AnalyticsEcommerce[], brand?: string) {
		const eventName = 'add_to_cart';
		const event: AnalyticsEvent = { event_name: eventName, value, ecommerce: { items } };

		if (brand) {
			event.wmm_brand = brand;
		}

		this._ecommEvent(event);
	}

	trackViewItem(items: AnalyticsEcommerce[], brand?: string) {
		const eventName = 'view_item';

		const event: AnalyticsEvent = { event_name: eventName, ecommerce: { items } };

		if (brand) {
			event.wmm_brand = brand;
		}

		this._ecommEvent(event);
	}

	trackViewItemList(items: AnalyticsEcommerce[], brand?: string) {
		const eventName = 'view_item_list';
		const event: AnalyticsEvent = { event_name: eventName, ecommerce: { items } };

		if (brand) {
			event.wmm_brand = brand;
		}

		this._ecommEvent(event);
	}

	trackViewCart(value, items: AnalyticsEcommerce[]) {
		const eventName = 'view_cart';
		const event: AnalyticsEvent = { event_name: eventName, value, ecommerce: { items } };
		this._ecommEvent(event);
	}

	trackExperiment(experimentName: string, variant: string) {
		const eventName = `experiment_${experimentName}`;
		this._ecommEvent({ event_name: eventName, event: variant });
	}

	trackSelectDate(timestamp: string, brand: string) {
		const date = moment(timestamp, 'YYYYMMDD').format('dddd, MMMM Do, YYYY');
		const eventName = 'select_date';
		this._ecommEvent({ event_name: eventName, wmm_brand: brand, events: { date_selected: date } });
	}

	trackSelectItem(brand: string, items: AnalyticsEcommerce[]) {
		const eventName = 'select_item';
		const event: AnalyticsEvent = { wmm_brand: brand, event_name: eventName, ecommerce: { items } };
		this._ecommEvent(event);
	}

	trackLogin() {
		const eventName = 'login';
		const event: AnalyticsEvent = { event_name: eventName, user: { logged_in: true } };
		this._userEvent(event);
	}

	trackLogout() {
		const eventName = 'logout';
		const event: AnalyticsEvent = { event_name: eventName, user: { logged_in: false } };
		this._userEvent(event);
	}

	trackRemoveFromCart2(value, item: AnalyticsEcommerce, brand: string) {
		const eventName = 'remove_from_cart';

		const event: AnalyticsEvent = { event_name: eventName, value, ecommerce: { items: [item] } };

		if (brand) {
			event.wmm_brand = brand;
		}

		this._ecommEvent(event);
	}

	trackPurchase(email, value, items: AnalyticsEcommerce[]) {
		const eventName = 'purchase';
		const event: AnalyticsEvent = {
			event_name: eventName,
			ecommerce: {
				value,
				currency: 'USD',
				items,
			},
			user: { email },
		};

		this._ecommEvent(event);
	}

	clearEcommerce() {
		window.dataLayer.push({ ecommerce: null });
	}

	generateAnalyticsEcommerceObject(
		itemId: string,
		itemName: string,
		itemBrand: string,
		itemCategory: string,
		paymentAmount,
		quantity?: number,
		itemListName?: string | false,
		itemCategory2?: string,
	): AnalyticsEcommerce {
		const _itemBrandFriendly = this._friendlyNameForBrand(itemBrand);
		return {
			item_id: this._slugify(itemId),
			item_name: itemName,
			item_brand: _itemBrandFriendly,
			item_category: itemCategory,
			price: paymentAmount,
			currency: 'USD',
			item_list_name: this._determineItemListName(itemListName, _itemBrandFriendly),
			quantity,
			item_category2: itemCategory2,
		};
	}

	_slugify(str: string) {
		if (!str) {
			return null;
		}

		return str
			.toLowerCase()
			.replace(/ +/g, '-');
	}

	_friendlyNameForBrand(brand: string) {
		if (!brand) {
			return null;
		}

		if (brand === 'eiq') {
			return 'eReaderIQ';
		}

		if (brand.includes('-')) {
			return this.sitesService.getFriendlySiteNameBySlug(brand);
		}

		return brand;
	}

	_determineItemListName(itemListName: string | false, itemBrand: string) {
		if (itemListName === false) {
			return null;
		}

		return itemListName || itemBrand;
	}

	/**
   * Event to track when an existing pub date is overwritten by a new pub date
   * @param {string} list
   * @param {object} productData
   */
	trackRemoveFromCart(list, productData) {
		const ecommerce = {
			remove: {
				actionField: {
					list,
				},
				products: [productData],
			},
		};
		this.trackGTMEvent('eec.remove', { ecommerce });
	}

	/**
   * Event to track when a new pub date is selected in purchase flow
   * @param {string} list
   * @param {object} productData
   */
	trackAddToCart(list, productData) {
		const ecommerce = {
			add: {
				actionField: {
					list,
				},
				products: [productData],
			},
		};
		this.trackGTMEvent('eec.add', { ecommerce });
	}

	/**
   * Event to track which checkout step the user is at
   * @param {string|number} step
   * @param {object} productData
   */
	trackCheckoutStep(step, productData) {
		const ecommerce = {
			checkout: {
				actionField: {
					step,
				},
				products: [productData],
			},
		};
		this.trackGTMEvent('eec.checkout', { ecommerce });
	}

	/**
   * Event to track checkout options associated with the checkout step.
   * Most likely will be used right after a `trackCheckoutStep` event is triggered.
   * @param {string|number} step
   * @param {object} option
   */
	trackCheckoutOption(step, option) {
		const ecommerce = {
			checkout_option: {
				actionField: {
					step,
					option,
				},
			},
		};
		this.trackGTMEvent('eec.checkout_option', { ecommerce });
	}

	/**
   * Event to track the promotion click after accepting the upsell
   * @param upsell
   */
	trackUpsellPromotionClick(upsell) {
		if (this.isValidUpsell(upsell)) {
			const { item } = upsell.to;
			const promotions = [{
				id: upsell._id,
				name: `Upsell - ${item._site.name} ${item.name} Feature`,
			}];
			const ecommerce = { promoClick: { promotions } };
			this.trackGTMEvent('eec.promotionClick', { ecommerce });
		}
	}

	/**
   * Event to track the promotion view GTM event
   * @param {Object} upselll
   */
	trackPromotionView(upsell) {
		const { item } = upsell.to;
		const promotions = [{
			id: upsell._id,
			name: `Upsell - ${item._site.name} ${item.name} Feature`,
		}];
		const ecommerce = { promoView: { promotions } };
		this.trackGTMEvent('eec.promotionView', { ecommerce });
	}

	/**
   * Event to track the upsell impression GTM event
   * @param {Object} upsell
   */
	trackUpsellImpressionView(upsell) {
		const { item } = upsell.to;
		const impressions = [{
			id: upsell._id,
			name: `Upsell - ${item._site.name} ${item.name} Feature`,
			category: `${item._site.name} / ${item.name}`,
			list: 'Upsell',
			position: 1,
			dimension1: 'Author',
		}];
		const ecommerce = { impressions };
		this.trackGTMEvent('eec.impressionView', { ecommerce });
	}

	/**
   * Returns a boolean indicating whether the given upsell
   * has all the needed fields or not
   * @param {Object} upsell
   */
	isValidUpsell(upsell) {
		return (
			upsell
      && typeof upsell === 'object'
      && upsell.to
      && upsell.to.item
      && upsell.to.item._site.name
      && upsell.to.item.name
		);
	}

	/**
   * Entry function for impressionable upsell events.
   * If the upsell is valid, the two functions below will
   * be triggered.
   * @param {Object} upsell
   */
	trackUpsellImpressionAndPromotionViews(upsell) {
		if (this.isValidUpsell(upsell)) {
			this.trackPromotionView(upsell);
			this.trackUpsellImpressionView(upsell);
		}
	}

	/**
   * Event to track genre impressions as they become visible on viewport.
   * @param {Map} genreMap Map whose key is seoSlug and value is object of genre display name and position in list
   * @param {string} site
   * @param {string} user either will be id of logged in user or empty string ''
   */
	trackAdvertImpressionView(genreMap, site, user = '') {
		if (genreMap && genreMap instanceof Map) {
			const impressions = [];
			genreMap.forEach((value, key) => {
				if (!value.sent) {
					impressions.push({
						id: key,
						name: `${site} ${value.name} Feature`,
						category: `${site} / ${value.name}`,
						list: `${site} for Authors`,
						position: value.position,
						dimension2: user,
					});
				}
			});
			const ecommerce = { impressions };
			this.trackGTMEvent('eec.impressionView', { ecommerce });
		}
	}

	trackEvent(eventName, properties = {}, callback: Function = () => {}) {
		if (typeof mixpanel !== 'undefined') {
			mixpanel.track(eventName, properties, callback);
		} else {
			setTimeout(() => { this.trackEvent(eventName, properties, callback); }, 250);
		}
	}

	identify(user) {
		if (typeof mixpanel !== 'undefined') {
			mixpanel.identify(user.username);
			mixpanel.people.set_once({
				$email: user.username,
				'Site Adverts Purchased': 0,
				'Lifetime Logins': 0,
				'Lifetime Revenue': 0,
			});
			mixpanel.people.set({
				$first_name: user.firstName,
				$last_name: user.lastName,
				authorLink: user.authorLink,
			});
			mixpanel.people.increment('Lifetime Logins', 1);
		} else {
			setTimeout(() => { this.identify(user); }, 250);
		}
	}

	alias(id) {
		if (typeof mixpanel !== 'undefined') {
			mixpanel.alias(id);
		} else {
			setTimeout(() => { this.alias(id); }, 250);
		}
	}

	track_charge(amount, data) {
		if (typeof mixpanel !== 'undefined') {
			mixpanel.people.track_charge(amount, data);
		} else {
			setTimeout(() => { this.track_charge(amount, data); }, 250);
		}
	}

	increment(property, amount) {
		if (typeof mixpanel !== 'undefined') {
			mixpanel.people.increment(property, amount);
		} else {
			setTimeout(() => { this.increment(property, amount); }, 250);
		}
	}

	superProperty(data) {
		if (typeof mixpanel !== 'undefined') {
			mixpanel.register(data);
		} else {
			setTimeout(() => { this.superProperty(data); }, 250);
		}
	}

	setInternalSource(source) {
		this.internalSource = source;
	}

	initPurchaseData() {
		this._purchaseData = {
			'Internal Source': this.internalSource ? this.internalSource : 'none',
		};
	}

	get purchaseData() {
		return this._purchaseData;
	}

	get referrer() {
		return this._referrer;
	}

	set referrer(referrer) {
		this._referrer = referrer;
	}

	incrementPurchase() {
		if (typeof mixpanel !== 'undefined') {
			mixpanel.people.increment('Site Adverts Purchased', 1);
		} else {
			setTimeout(() => { this.incrementPurchase(); }, 250);
		}
	}

	trackPage(url) {
		if (typeof window.dataLayer !== 'undefined' && this.user) {
			if (!this.us.isAdminOrSuperAdmin()) {
				const sanitizedUrl = this.parseUrl(url);
				window.dataLayer.push({ event: 'pageview', page: sanitizedUrl });
			}
		} else {
			setTimeout(() => { this.trackPage(url); }, 250);
		}
	}

	parseUrl(url) {
		const querySplit = url.split('?');
		const slashSplit = querySplit[0].split('/');

		const urlMap = {
			'authorize-email': 'authorize-email/email/code',
			'reset-password': 'reset-password/reset-code',
			receipt: 'receipt/feature-id',
			'confirm-account': (segments) => {
				let newUrl = 'confirm-account/user-name';
				if (segments[3] && segments[3] !== 'new' && segments[3] !== 'login') {
					newUrl += '/code';
				} else if (segments[3]) {
					newUrl += `/${segments[3]}`;
				}
				return newUrl;
			},
			'author-dashboard': (segments) => {
				let newUrl = 'author-dashboard/user-name';
				if (segments[3]) {
					newUrl += `/${segments[3]}`;
					if (segments[3] === 'books' && segments[4]) {
						newUrl += '/book-id';
					} else if (segments[3] === 'promotions' && segments[4]) {
						newUrl += '/feature-id';
					} else if (segments[3] === 'features') {
						if (segments[4] && segments[4] !== 'order-checkout') {
							newUrl += '/site-slug';
							if (segments[5]) {
								newUrl += '/site-advert-slug';
								if (segments[6] === 'checkout' && segments[7]) {
									newUrl += '/checkout/feature-id';
								} else if (segments[6] && !segments[7]) {
									newUrl += '/date';
								}
							}
						} else if (segments[4] === 'order-checkout' && segments[5]) {
							newUrl += '/order-checkout/order-id';
							if (segments[6] === 'success') {
								newUrl += '/success';
							}
						} else if (segments[4]) {
							newUrl += `/${segments[4]}`;
						}
					}
				}
				return newUrl;
			},
			features: (segments) => {
				let newUrl = 'features';
				if (segments[2]) {
					if (segments[2] === 'order-checkout' && segments[3]) {
						newUrl += '/order-checkout/order-id';
						if (segments[4] === 'success') {
							newUrl += '/success';
						}
					} else {
						newUrl += '/site-slug';
						if (segments[3]) {
							newUrl += '/site-advert-slug';
							if (segments[4] === 'checkout' && segments[5]) {
								newUrl += '/checkout/feature-id';
							} else if (segments[4] && !segments[5]) {
								newUrl += '/date';
							}
						}
					}
				}
				return newUrl;
			},
			admin: (segments) => {
				let newUrl = `admin/${segments[2]}`;
				if (segments[3]) {
					const adminPaths = {
						'daily-ops': '/site-id/pub-date-id',
						'email-campaigns': '/email-campaign-id',
						'featured-books': '/feature-id',
						inventory: '/site-id',
						'promo-codes': '/promo-code-id',
						'site-adverts': '/site-advert-id',
						sites: '/site-id',
					};
					newUrl += adminPaths[segments[2]] || '';
				}
				return newUrl;
			},
		};

		let newUrl = urlMap[slashSplit[1]];

		if (typeof newUrl === 'function') {
			newUrl = newUrl(slashSplit);
		}

		if (querySplit[1]) {
			newUrl += `?${querySplit[1]}`;
		}

		return newUrl;
	}
}
