import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy, ViewChild, OnChanges, OnInit } from '@angular/core';
import datepicker from 'js-datepicker';
import * as moment from 'moment';

@Component({
	selector: 'app-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements AfterViewInit, OnDestroy, OnChanges, OnInit {
	@Input() startDate: Date | string;

	@Input() maxDate: Date | string;

	@Input() minDate: Date | string;

	@Input() label: string;

	@Input() placeholder: string;

	@Input() id: string;

	@Input() format = 'MM/DD/YYYY';

	@Input() tooltipLabel: string;

	@Input() tooltipText: string;

	@Input() required: boolean;

	@Output() dateChange = new EventEmitter<Date>();

	@ViewChild('datepicker') datepickerElement;

	datepicker: any;

	dp: any;

	constructor() {
		this.datepicker = datepicker;
	}

	getOptions() {
		const startDate = this.startDate ? new Date(this.startDate) : null;
		const maxDate = this.maxDate ? new Date(this.maxDate) : null;
		const minDate = this.minDate ? new Date(this.minDate) : null;

		return {
			id: this.id,
			startDate,
			dateSelected: startDate,
			maxDate,
			minDate,
			onSelect: (instance, date) => {
				this.dateChange.emit(date);
				const otherInstance = instance.sibling;
				if (date !== undefined && otherInstance.dateSelected === undefined) {
					otherInstance.show();
				}
			},
			formatter: (input, date, instance) => {
				input.value = moment(date).format(this.format);
			},
		};
	}

	ngOnChanges(changes) {
		if (changes.startDate) {
			const startDate = changes.startDate.currentValue ? new Date(changes.startDate.currentValue) : null;
			this.dp?.setDate(startDate);
		}

		if (changes.maxDate) {
			const maxDate = changes.maxDate.currentValue ? new Date(changes.maxDate.currentValue) : null;
			this.dp?.setMax(maxDate);
		}

		if (changes.minDate) {
			const minDate = changes.minDate.currentValue ? new Date(changes.minDate.currentValue) : null;
			this.dp?.setMin(minDate);
		}
	}

	ngOnInit() {
		this.handleDates();
	}

	ngAfterViewInit() {
		this.init();
	}

	ngOnDestroy() {
		this.destroy();
	}

	handleDates() {
		if (this.minDate && this.maxDate) {
			const minDate = moment(this.minDate).subtract(1, 'day');
			const maxDate = moment(this.maxDate).add(1, 'day');
			if (moment(minDate).isAfter(maxDate)) {
				this.maxDate = null;
			}

			if (moment(maxDate).isBefore(minDate)) {
				this.minDate = null;
			}

			if (moment(this.startDate).isBetween(minDate, maxDate) === false) {
				this.startDate = null;
			}
		}
	}

	init() {
		const options = this.getOptions();
		this.dp = this.datepicker(this.datepickerElement.nativeElement, options);
	}

	destroy() {
		if (this.dp) {
			this.dp.remove();
		}
	}
}
