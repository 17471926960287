<div class="background">
  <div class="flex login-body">
    <div class="img-aside flex">
        <div class="card">
          <div class="body">
            <img src="/assets/img/logos/wwm_lightgreen.png" alt="Author Dashboard" />
            <h4>As a Free Member, you will receive:</h4>
            Access to the author dashboard<br>
            The ability to store your book info<br>
            Insight into your past promo history<br>
            The ability to easily re-run promos<br>
            Access to our Facebook Author Community<br>
          </div>
        </div>
    </div>
    <form class="form" [formGroup]="form" (ngSubmit)="captchaRef.execute()">
        <h3>Create a Free Account</h3>
        <re-captcha
          #captchaRef="reCaptcha"
          siteKey="{{reCaptchaKey}}"
          size="invisible"
          (resolved)="$event && onSubmit($event)"
        ></re-captcha>
        <app-input-form [id]="formComponents.firstName.key" [label]="formComponents.firstName.label"
        [type]="formComponents.firstName.type" [required]="formComponents.firstName.required" [form]="form"></app-input-form>
        <app-input-form [id]="formComponents.lastName.key" [label]="formComponents.lastName.label"
        [type]="formComponents.lastName.type" [required]="formComponents.lastName.required" [form]="form"></app-input-form>
        <app-input-form [id]="formComponents.email.key" [label]="formComponents.email.label"
        [type]="formComponents.email.type" [required]="formComponents.email.required" [form]="form"></app-input-form>
        <app-input-form [id]="formComponents.password.key" [label]="formComponents.password.label"
        [type]="formComponents.password.type" [required]="formComponents.password.required" [form]="form"></app-input-form>
        <div class="checkbox">
            <input type="checkbox" id="agreeTerms">
            I agree to the 
            <a href="https://www.writtenwordmedia.com/privacy-policy" target="_blank">Terms and Conditions</a>
        </div>
        <div class="button-line">
          <button type="submit" class="btn block">Create Account</button>
        </div>
        <button class="btn invert login" routerLink="/login">Login</button>
    </form>
</div>
</div>
<app-error-modal [maskClose]="true" [modalId]="'error-modal'" [text]="errorText" [extraButtons]="extraButton"></app-error-modal>
