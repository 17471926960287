<app-modal [id]="modalId" [maskClose]="false">
  <aside class="modal cart-modal">
    <div [@slideInOut]="visible ? 'open' : 'closed'" class="modal-body">
      <div class="header">
        <div class="cart-logo">
          <img [src]="'/assets/img/defaults/shopping-cart-black.png'" >
          <p class="title">Cart</p>
        </div>
        <div class="button regular close" (click)="close()"><img src="../../../assets/img/defaults/chevron-up.png" /></div>
      </div>
      <div class="spinner" *ngIf="loading"><div></div><div></div><div></div><div></div></div>
      <div *ngIf="order && order.items && order.items.length && !loading">
        <div class="content" *ngFor="let item of order.items">
          <app-cart-subscription-nugget [item]="item" *ngIf="item.itemType === 'subscription'"></app-cart-subscription-nugget>
          <app-cart-feature-nugget [item]="item" *ngIf="item.itemType === 'featuredBook'"></app-cart-feature-nugget>
          <app-cart-promo-stack-nugget [item]="item" *ngIf="item.itemType === 'promoStack'"></app-cart-promo-stack-nugget>
        </div>
        <div class="footer">
          <div class="totals">
            <p class="total-amount">
              Total
              <span class="quantity" *ngIf="order && order.items && order.items.length && !loading">
              ({{order.items.length}} items)
            </span>
            </p>
            <p class="total-amount">${{orderTotalAmount.toFixed(2)}}</p>
          </div>
          <button class="regular button solid-green checkout-now" (click)="checkout()">Checkout Now</button>
          <a class="continue-shopping" (click)="continueShopping()">Continue Shopping</a>
        </div>
      </div>
     <div *ngIf="!order || !order.items || !order.items.length">
       <p class="empty" >Your cart is empty!</p>
     </div>
    </div>
    <div class="cart-modal-background" (click)="close()"></div>
  </aside>
</app-modal>
