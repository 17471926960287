import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { UserService } from '../../core/services/data/user.service';
import { LoginForm } from '../login/login-form';
import { InputControlService } from '../../core/services/input-control.service';
import { ModalService } from '../../core/services/modal.service';
import { AnalyticsService } from '../../core/services/analytics.service';

@Component({
	selector: 'app-confirm-account',
	templateUrl: './confirm-account.component.html',
	styleUrls: ['./confirm-account.component.scss'],
})
export class ConfirmAccountComponent implements OnInit, OnDestroy {
	public state = 'waiting';

	public author;

	public formComponents = new LoginForm();

	public form: UntypedFormGroup;

	public errorText = [];

	private author$;

	constructor(
		private route: ActivatedRoute,
		private us: UserService,
		private ics: InputControlService,
		private router: Router,
		private ms: ModalService,
		private as: AnalyticsService,
	) { }

	ngOnInit() {
		const parts = window.location.pathname.split('/');
		this.us.fetchAuthor(parts[2]);
		this.author$ = this.us.author$.subscribe((data: any) => {
			this.author = data;
			if (this.route.snapshot.params.code === 'new') {
				this.state = 'new';
			} else if (this.route.snapshot.params.code === 'login') {
				this.state = 'login';
			} else {
				this.us.checkConfirmCode(this.route.snapshot.params.author, this.route.snapshot.params.code).subscribe((result: any) => {
					if (result.success) {
						this.state = 'confirmed';
						this.as.trackEvent('Signup - Confirmed');
						this.as.trackGTMEvent('account_created', { confirmed: true });
						this.formComponents.email.value = this.author.username;
						this.form = InputControlService.toFormGroup(this.formComponents);

						setTimeout(() => {
							this.router.navigate([`/author-dashboard/${this.author.authorLink}/home`]).then();
						}, 2000);
					} else if (this.author.confirmed) {
						this.state = 'confirmed';
						this.formComponents.email.value = this.author.username;
						this.form = InputControlService.toFormGroup(this.formComponents);
					} else if (result.message === 'timedout') {
						this.state = 'old';
					} else {
						this.state = 'invalid';
					}
				});
			}
		});
	}

	ngOnDestroy() {
		this.author$.unsubscribe();
	}

	resendEmail() {
		this.us.resendConfirm(this.author.authorLink).subscribe((data: any) => {
			if (data.success) {
				this.state = 'new';
			} else {
				this.state = 'send-error';
			}
		});
	}

	onSubmit(event) {
		event.preventDefault();
		const errors = this.ics.checkFormValid(this.form, this.formComponents);
		if (errors.hasErrors) {
			this.errorText = errors.errorText;
			this.ms.open('error-modal');
		} else {
			this.us.login(this.form.value.email, this.form.value.password).subscribe(
				(data: any) => {
					if (data.success) {
						this.us.updateUser(data.user);
						this.as.identify(data.user.username);
						const supers = {
							'Logged In': true,
							Employee: false,
						};
						if (this.us.isAdminOrSuperAdmin()) {
							supers.Employee = true;
						}
						this.as.superProperty(supers);
						this.router.navigate([`/author-dashboard/${data.user.authorLink}/home`]).then();
						this.as.trackEvent('Login', { type: 'Email' });
						this.as.trackGTMEvent('login', { type: 'email' });
					} else if (data.message === 'Not Confirmed') {
						this.router.navigate([`/confirm-account/${data.user.authorLink}/login`]).then();
					} else {
						this.errorText = ['Invalid Email/Password'];
						this.ms.open('error-modal');
					}
				},
			);
		}
	}
}
