import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../core/services/data/user.service';
import { MemberRoleService } from '../../core/services/data/member-roles.service';
import { ModalService } from '../../core/services/modal.service';
import { ServerConnectionService } from '../../core/services/server-connection.service';
import { AnalyticsService } from '../../core/services/analytics.service';

import { IUser } from '../../types/datatypes';
import { MemberRolesAPIResponse, SettingAPIResponse } from '../../types/responses';
import { SettingsService } from '../../core/services/data/settings.service';
import { MembershipUpgradeService } from '../../core/services/data/membership-upgrade.service';

@Component({
	selector: 'app-subscriptions',
	templateUrl: './subscriptions.component.html',
	styleUrls: ['./subscriptions.component.scss'],
	animations: [
		trigger('fadeOut1', [
			state('true', style({ opacity: '1' })),
			state('false', style({ opacity: '0' })),
			transition('true => false', animate('1s ease-in')),
		]),
		trigger('fadeIn1', [
			state('true', style({ opacity: '1' })),
			state('false', style({ opacity: '0' })),
			transition('false => true', animate('1ms')),
		]),
		trigger('fadeOut2', [
			state('true', style({ opacity: '1' })),
			state('false', style({ opacity: '0' })),
			transition('true => false', animate('1ms')),
		]),
		trigger('fadeIn2', [
			state('true', style({ opacity: '1' })),
			state('false', style({ opacity: '0' })),
			transition('false => true', animate('1s ease-in')),
		]),
	],
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
	public stripe;

	public elements;

	public card;

	public loading = true;

	public loadingStart = true;

	public hasErrors;

	public dissableButton;

	public errorText;

	public successText;

	public hasCustomer = false;

	public hasSubscription = false;

	public author;

	public author$;

	public selectedPlan = '';

	public memberDiscounts = [];

	public existingRole: string;

	public plans = [
		{
			// Internal values
			id: '',
			discountCap: 0,
			discountPercentage: 0,
			internalName: 'free',
			roleAccess: 1,
			// Display values
			name: 'Free',
			textSecondary: '',
			price: 0,
			current: true,
			off: '',
			text: 'I want to get organized and save time',
			centralized: true,
			saved: true,
			email: true,
			author: true,
			discount: false,
			early: false,
			partner: false,
			access: false,
			chat: false,
			priceDiscounted: null,
			betaProducts: false,
			editorial: false,
		},
		{
			// Internal values
			id: '',
			discountCap: 0,
			discountPercentage: 0,
			internalName: 'basic',
			roleAccess: 2,
			// Display values
			name: 'Gold',
			textSecondary: 'Most Popular Plan',
			price: 100,
			current: false,
			off: '(10% off all products up to a $200 cap)',
			text: 'I’m ready to level up my book marketing',
			centralized: true,
			saved: true,
			email: true,
			author: true,
			discount: true,
			early: true,
			partner: true,
			access: true,
			chat: true,
			priceDiscounted: null,
			betaProducts: false,
			editorial: false,
		},
		{
			// Internal values
			id: '',
			discountCap: 0,
			discountPercentage: 0,
			internalName: 'premium',
			roleAccess: 3,
			// Display values
			name: 'Platinum',
			textSecondary: 'Best Value',
			price: 250,
			current: false,
			off: '(10% off all products up to a $500 cap)',
			text: 'Get me on the bestseller list!',
			centralized: true,
			saved: true,
			email: true,
			author: true,
			discount: true,
			early: true,
			partner: true,
			access: true,
			chat: true,
			priceDiscounted: null,
			betaProducts: true,
			editorial: true,
		},
	];

	constructor(
		private us: UserService,
		private ms: ModalService,
		private mrs: MemberRoleService,
		private router: Router,
		private route: ActivatedRoute,
		private scs: ServerConnectionService,
		private ss: SettingsService,
		private as: AnalyticsService,
		private membershipUpgradeService: MembershipUpgradeService,
	) {}

	ngOnInit(): void {
		if (!this.us.me) {
			this.router.navigate(['/login']);
			return;
		}

		const urlPathname = window.location.pathname;

		if (this.us.me && urlPathname === '/subscriptions' && urlPathname.includes(this.us.me.authorLink) === false) {
			this.router.navigate([`/author-dashboard/${this.us.me?.authorLink}/subscriptions`]);
		}

		this.author$ = this.us.author$.subscribe(
			(data: IUser) => {
				this.author = data;

				this.existingRole = this.author?.memberRole?.roleName;

				if (this.existingRole) {
					for (const plan of this.plans) {
						plan.current = plan.name === this.existingRole;
					}
				}

				// if (!this.us.isFreeMember()) {
				// 	this.router.navigate(['/features']);
				// }

				this.mrs.fetchMemberRoles().subscribe((rolesData: MemberRolesAPIResponse) => {
					const fetchedPlans = rolesData && rolesData.success ? rolesData.roles : [];

					for (const fetchedPlan of fetchedPlans) {
						const index = this.plans.findIndex((plan) => plan.roleAccess === fetchedPlan.roleAccess);

						if (index !== -1) {
							if (fetchedPlan.roleName) this.plans[index].name = fetchedPlan.roleName;
							if (fetchedPlan.discountCap) this.plans[index].discountCap = fetchedPlan.discountCap;
							if (fetchedPlan.discountPercentage) this.plans[index].discountPercentage = fetchedPlan.discountPercentage;
							if (fetchedPlan.price) this.plans[index].price = fetchedPlan.price;
							if (fetchedPlan.priceDiscounted) this.plans[index].priceDiscounted = fetchedPlan.priceDiscounted;
							if (fetchedPlan.roleAccess > 1) {
								// eslint-disable-next-line
								this.plans[index].off = `(${fetchedPlan.discountPercentage}% off all products up to a $${fetchedPlan.discountCap} cap)`;
							}
						}
					}

					this.checkUrl();
					this.ss.getMemberDiscountSettings('gold').subscribe((goldMemberSetting: SettingAPIResponse) => {
						if (goldMemberSetting.success) {
							this.memberDiscounts[2] = JSON.parse(goldMemberSetting.setting.value.toLowerCase());
						}
					});
					this.ss.getMemberDiscountSettings('platinum').subscribe((platinumMemberSetting: SettingAPIResponse) => {
						if (platinumMemberSetting.success) {
							this.memberDiscounts[3] = JSON.parse(platinumMemberSetting.setting.value.toLowerCase());
						}
						this.loading = false;
					});
				});
			},
		);

		const items = this.plans.map((plan) => this.as.generateAnalyticsEcommerceObject(
			plan.internalName,
			plan.name,
			null,
			null,
			plan.price,
			1,
			'Memberships',
			'Memberships',
		));

		this.as.trackViewItemList(items);
	}

	checkUrl() {
		if (this.route.snapshot.params.plan) {
			const namePlan = this.route.snapshot.params.plan[0].toUpperCase() + this.route.snapshot.params.plan.slice(1);
			this.plans.forEach((plan) => {
				if (plan.name === namePlan) {
					this.openModal(plan.internalName);
				}
			});
		}
	}

	openModal(plan) {
		this.membershipUpgradeService.select(plan.internalName);
		this.ms.open('update-card-form');

		const ecommerceItem = this.as.generateAnalyticsEcommerceObject(
			plan.internalName,
			plan.name,
			null,
			null,
			plan.price,
			1,
			'Memberships',
			'Memberships',
		);

		this.as.trackViewItem([ecommerceItem]);
	}

	planSelected() {
		this.selectedPlan = '';
	}

	ngOnDestroy() {
		this.author$.unsubscribe();
	}

	isFreeMember() {
		return this.us.isFreeMember();
	}

	discountEnabled(roleAccess) {
		return this.memberDiscounts[roleAccess];
	}
}
