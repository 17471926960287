import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreModule } from '../../core.module';
import { ServerConnectionService } from '../server-connection.service';

@Injectable({
	providedIn: CoreModule,
})
export class GiveawayService {
	constructor(
		private scs: ServerConnectionService,
	) { }

	public getDownload(featureId, userId) {
		if (!userId) return new Observable();

		return this.scs.http$('POST', '/api/giveaways/download/', null, { featureId, userId });
	}

	public getCallouts(userId) {
		return this.scs.http$('POST', '/api/giveaways/callouts', null, { userId });
	}

	public clearNotification(id) {
		return this.scs.http$('POST', '/api/giveaways/clear-notification', null, { id });
	}

	public joinAuthorsList(body) {
		return this.scs.http$('POST', '/api/giveaways/join-authors-list', null, body);
	}

	public createUnaffiliated(email: string, siteAdvertId: string) {
		return this.scs.http$('POST', '/api/giveaways/unaffiliated/create', null, { email, siteAdvertId });
	}

	public checkStatusAuthorsAndSTLists(body) {
		return this.scs.http$('POST', '/api/giveaways/check-status-authors-and-sailthru-list', null, body);
	}
}
