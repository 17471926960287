import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { GiveawaysComponent } from './giveaways.component';

@NgModule({
	imports: [
		RouterModule,
		SharedModule,
	],
	declarations: [
		GiveawaysComponent,
	],
})
export class GiveawaysModule {
}
