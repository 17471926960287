import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { ForgotPasswordForm } from './forgot-password-form';
import { ResetPasswordForm } from './reset-password-form';
import { InputControlService } from '../../core/services/input-control.service';
import { ModalService } from '../../core/services/modal.service';
import { UserService } from '../../core/services/data/user.service';
import { AnalyticsService } from '../../core/services/analytics.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['../login/login.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
	public errorText;

	public passwordHidden = {
		password1: true,
		password2: true,
	};

	public formComponents = new ForgotPasswordForm();

	public formComponents2 = new ResetPasswordForm();

	public form: UntypedFormGroup;

	public form2: UntypedFormGroup;

	public goodCode;

	public showForm2 = false;

	public successText;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private ics: InputControlService,
		private ms: ModalService,
		private us: UserService,
		private as: AnalyticsService,
	) { }

	ngOnInit() {
		if (this.route.snapshot.params.resetCode) {
			this.showForm2 = true;
			this.us.checkCode(this.route.snapshot.params.resetCode, this.route.snapshot.params.authorLink).subscribe(
				(data: any) => {
					if (data.success) {
						this.form2 = InputControlService.toFormGroup(this.formComponents2);
						this.goodCode = true;
					} else {
						this.goodCode = false;
					}
				},
			);
		} else {
			this.form = InputControlService.toFormGroup(this.formComponents);
		}
	}

	done() {
		if (this.showForm2) {
			this.router.navigate(['/user/login']).then();
		} else {
			this.router.navigate(['/']).then();
		}
	}

	onSubmit(event) {
		event.preventDefault();
		const errors = this.ics.checkFormValid(this.form, this.formComponents);
		if (errors.hasErrors) {
			this.errorText = ['Oops', 'Looks like there was a problem with the information you submitted.'];
			this.ms.open('error-modal');
		} else {
			this.us.resetPassword(this.form.value.email).subscribe(
				(data: any) => {
					if (data.success) {
						this.as.trackEvent('Reset Password Attempt', { email: this.form.value.email });
						this.as.trackGTMEvent('reset_password_attempt', { email: this.form.value.email });
						this.successText = 'You should receive an email shortly with reset instructions.';
						this.ms.open('success-modal');
					} else {
						this.errorText = ['There was an error trying to send your reset password request.'];
						this.ms.open('error-modal');
					}
				},
			);
		}
	}

	resetPassword(event) {
		event.preventDefault();
		this.errorText = [];
		const errors = this.ics.checkFormValid(this.form2, this.formComponents2);
		if (errors.hasErrors) {
			this.errorText = errors.errorText;
		}
		if (this.form2.value.password1 !== this.form2.value.password2) {
			if (this.errorText.length === 0) {
				this.errorText = ['Unable to continue:'];
			}
			this.errorText.push('&#160;&#160;The password fields must match');
		}
		if (this.errorText.length > 0) {
			this.ms.open('error-modal');
		} else {
			this.us.sendReset(this.form2.value.password1, this.route.snapshot.params.resetCode,
				this.route.snapshot.params.authorLink).subscribe(
				(data: any) => {
					if (data.success) {
						this.as.trackEvent('Reset Password Success');
						this.as.trackGTMEvent('reset_password_success');
						this.ms.open('confirm-modal');
					} else {
						this.errorText = ['There was an error trying to reset your password.'];
						this.ms.open('error-modal');
					}
				},
			);
		}
	}

	togglePasswordAttribute(passwordElmId) {
		const passwordInput = document.getElementById(passwordElmId);
		if (passwordInput) {
			if (this.passwordHidden[passwordElmId]) {
				passwordInput.setAttribute('type', 'text');
				this.passwordHidden[passwordElmId] = false;
			} else {
				passwordInput.setAttribute('type', 'password');
				this.passwordHidden[passwordElmId] = true;
			}
		}
	}

	changeToReset() {
		this.form = InputControlService.toFormGroup(this.formComponents);
		this.showForm2 = false;
	}

	handleConfirm(event) {
		if (event) {
			this.router.navigate([`/author-dashboard/${this.route.snapshot.params.authorLink}/home`]).then();
		}
	}
}
