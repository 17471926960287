import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CoreModule } from '../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class InputControlService {
	constructor() { }

	static getRegex(pattern) {
		let val: RegExp;
		switch (pattern) {
			case 'email':
				// eslint-disable-next-line max-len
				val = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				break;
			case 'number':
				val = /^[ 0-9]*$/;
				break;
			case 'code':
				val = /^[A-za-z0-9!'-]+$/;
				break;
			case 'letters':
				val = /^[A-Za-z]+$/;
				break;
			case 'phone':
				val = /^[^A-WYZa-wyz]*$/;
				break;
			default:
				val = /^[A-Za-z0-9-!' .]+$/;
				break;
		}
		return val;
	}

	static toFormGroup(inputs) {
		const group: any = {};
		const added = [];
		for (const input in inputs) {
			// eslint-disable-next-line no-prototype-builtins
			if (inputs.hasOwnProperty(input)) {
				if (inputs[input]?.key && added.indexOf(inputs[input].key) === -1) {
					// adjust based on provided validation requirements
					const validators = [];
					if (inputs[input].required) {
						validators.push(Validators.required);
					}
					if (inputs[input].minLength > 0) {
						validators.push(Validators.minLength(inputs[input].minLength));
					}
					if (inputs[input].maxLength > 0) {
						validators.push(Validators.maxLength(inputs[input].maxLength));
					}
					if (inputs[input].pattern !== '') {
						validators.push(Validators.pattern(InputControlService.getRegex(inputs[input].pattern)));
					}
					const value1 = inputs[input].value;
					let value2;
					if (inputs[input].disabled) {
						value2 = { value: inputs[input].value || '' };
						value2.disabled = true;
					}
					group[inputs[input].key] = new UntypedFormControl(value2 || value1, validators);
					added.push(inputs[input].key);
				}
			}
		}
		return new UntypedFormGroup(group);
	}

	public checkFormValid(form, inputs) {
		const errorObj = {
			hasErrors: false,
			errorText: ['Unable to continue: '],
		};
		Object.keys(form.controls).forEach(
			(field) => {
				const control = form.get(field);
				if (control instanceof UntypedFormControl) {
					control.markAsTouched({ onlySelf: true });
					if (control.errors) {
						errorObj.hasErrors = true;
						const fieldName = inputs[field].label;
						Object.keys(control.errors).forEach(
							(errorType) => {
								switch (errorType) {
									case 'required':
										errorObj.errorText.push(`&#160;&#160;The <strong>${fieldName}</strong> field is required`);
										break;
									case 'minlength':
										errorObj.errorText.push(`&#160;&#160;The <strong>${fieldName} </strong>
                        must be at least ${control.errors[errorType].requiredLength} characters`);
										break;
									case 'maxlength':
										errorObj.errorText.push(`&#160;&#160;The <strong>${fieldName} </strong>
                      must be ${control.errors[errorType].requiredLength} characters or less`);
										break;
									default:
										errorObj.errorText.push(`&#160;&#160;The <strong>${fieldName} has an unknown error </strong>`);
								}
							},
						);
					}
				}
			},
		);
		return errorObj;
	}
}
