import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ModalService } from '../../core/services/modal.service';
import { FeaturedBooksService } from '../../core/services/data/featured-books.service';
import { MailToForm } from './mail-to-form';
import { InputControlService } from '../../core/services/input-control.service';

@Component({
	selector: 'app-mail-to',
	templateUrl: './mail-to.component.html',
	styleUrls: ['./mail-to.component.scss'],
})
export class MailToComponent implements OnInit {
	@Input()
	set data(data) {
		if (data) {
			this._data = data;
			if (this._data.email) {
				this.form.controls.email.patchValue(this._data.email);
			}
		}
	}

	@Input() modalId;

	public text;

	public finished = false;

	public form: UntypedFormGroup;

	public mailForm = new MailToForm();

	public _data;

	public errorText;

	constructor(
		private ms: ModalService,
		private fbs: FeaturedBooksService,
		private ics: InputControlService,
	) { }

	ngOnInit() {
		this.form = InputControlService.toFormGroup(this.mailForm);
	}

	close() {
		this.form.controls.email.patchValue(this._data.email);
		this.finished = false;
		this.ms.close(this.modalId);
	}

	sendMail(event) {
		event.preventDefault();
		const errors = this.ics.checkFormValid(this.form, this.mailForm);
		if (errors.hasErrors) {
			this.errorText = errors.errorText;
			this.ms.open('mf-error-modal');
		} else {
			this._data.email = this.form.value.email;
			this.fbs.sendMail(this._data, this.form.value.content).subscribe();
			this.finished = true;
			this.form.reset();
		}
	}
}
