import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/data/user.service';
import { CoreModule } from '../core.module';
import { UtilitiesService } from '../services/utilities.service';

@Injectable({
	providedIn: CoreModule,
})
export class LoginGuard {
	public user;

	constructor(private router: Router,
		private us: UserService,
		private uts: UtilitiesService) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.checkForUser(next.params).then(
			() => true,
			() => {
				const fullUrl = window.location.pathname;
				if (this.user && fullUrl.includes('subscriptions')) {
					const url = next.url.pop();
					this.router.navigate([`/author-dashboard/${this.user.authorLink}/subscriptions/${url.path}`]).then();
					return true;
				}
				const url = next.url.pop();
				this.uts.saveRoute(url.path);
				this.router.navigate(['/login']).then();
				return false;
			},
		);
	}

	checkForUser(params) {
		return new Promise<boolean>((resolve, reject) => {
			this.us.fetchUser().then(
				(user: any) => {
					this.user = user;
					if (this.us.isAdminOrSuperAdmin()) {
						resolve(true);
					} else if (params.authorLink === user.authorLink) {
						resolve(true);
					} else {
						// eslint-disable-next-line prefer-promise-reject-errors
						reject(false);
					}
				}, () => {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(false);
				},
			);
		});
	}
}
