import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'app-input-form',
	templateUrl: './input-form.component.html',
	styleUrls: ['./input-form.component.scss'],
})
export class InputFormComponent {
	@Input() form: UntypedFormGroup;

	@Input() id: string;

	@Input() type = 'text';

	@Input() label: string;

	@Input() value = null;

	@Input() disabled = false;

	@Input() readonly = false;

	@Input() required = false;

	@Input() min = null;

	@Input() max = null;

	@Input() tooltipLabel: string;

	@Input() tooltipText: string;

	@Input() buttonText?: string;

	@Input() icon?: string;

	@Input() helper?: string;

	@Input() placeholder = '';

	@Output() clickHappened = new EventEmitter();

	@Output() buttonClick = new EventEmitter();

	constructor() { }

	clickEvent() {
		this.clickHappened.emit();
	}

	buttonClickEvent() {
		this.buttonClick.emit();
	}

	preventOutOfRange(e) {
		if (Number.isInteger(this.min) && e.target.value < this.min) {
			e.target.value = this.min;
		}

		if (Number.isInteger(this.max) && e.target.value > this.max) {
			e.target.value = this.max;
		}
	}
}
