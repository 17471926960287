import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CoreModule } from '../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class UtilitiesService {
	private _moment = moment;

	private _route;

	constructor() {
	}

	// moment
	get momentInstance() {
		return this._moment;
	}

	get route() {
		return this._route;
	}

	uniqueArray(array, key) {
		const flags = {};
		return array.filter(
			(entry) => {
				if (flags[entry[key]]) {
					return false;
				}
				flags[entry[key]] = true;
				return true;
			},
		);
	}

	saveRoute(path) {
		this._route = path;
	}

	public round(value: number): number {
		return Math.round(value * 10) / 10;
	}

	public formatStringToNumber(value: string): string {
		// Ensure the value is a string
		if (typeof value !== 'string') {
		  return value;
		}

		// Find the position of the last comma
		const lastCommaIndex = value.lastIndexOf(',');

		// If no comma is found, return the value as it is
		if (lastCommaIndex === -1) {
		  return value;
		}

		// Handle case where there is only one comma used as a decimal separator
		if (value.indexOf(',') === lastCommaIndex) {
		  const integerPart = value.slice(0, lastCommaIndex).replace(/,/g, '');
		  const decimalPart = value.slice(lastCommaIndex + 1);
		  return `${integerPart}.${decimalPart}`;
		}

		// Otherwise, split the value into integer and decimal parts
		const integerPart = value.slice(0, lastCommaIndex).replace(/,/g, '');
		const decimalPart = value.slice(lastCommaIndex + 1);

		// Concatenate integer and decimal parts with a dot
		return `${integerPart}.${decimalPart}`;
	  }
}
