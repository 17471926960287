import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginV2Component } from '../login/loginV2/login.component';
import { ForgotPasswordComponent } from '../login/forgot-password/forgot-password.component';
import { LandingComponent } from '../unattached/landing/landing.component';
import { SignupV2Component } from '../login/signupV2/signup.component';
import { ReceiptComponent } from '../unattached/receipt/receipt.component';
import { OrderReceiptComponent } from '../unattached/order-receipt/order-receipt.component';
import { SupportComponent } from '../unattached/support/support.component';
import { ConfirmAccountComponent } from '../login/confirm-account/confirm-account.component';
import { TermsComponent } from '../unattached/terms/terms.component';

import { ConfirmAuthComponent } from '../author-dashboard/confirm-auth/confirm-auth.component';
import { SubscriptionsComponent } from '../author-dashboard/subscriptions/subscriptions.component';
import { LoginGuard } from '../core/guards/login.guard';
import { GiveawaysComponent } from '../giveaways/giveaways.component';

export const routes: Routes = [
	{ path: '', component: LandingComponent },
	{ path: 'giveaways/:siteAdvertId', component: GiveawaysComponent, data: { title: 'Giveaways' } },
	{ path: 'subscriptions', component: SubscriptionsComponent, canActivate: [LoginGuard], pathMatch: 'prefix', data: { title: 'Subscriptions' } },
	{
		path: 'author-dashboard',
		loadChildren: () => import('../author-dashboard/author-dashboard.module').then((m) => m.AuthorDashboardModule),
	},
	{ path: 'confirm-account/:author/:code', component: ConfirmAccountComponent, data: { title: 'Confirm Account' } },
	{
		path: 'features',
		loadChildren: () => import('../features/features.module').then((m) => m.FeaturesModule),
	},
	{ path: 'features2', redirectTo: '/features' },
	{ path: 'promo-stacks', loadChildren: () => import('../promo-stacks/promo-stacks.module').then((m) => m.PromoStackModule) },
	{ path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot Password' } },
	{ path: 'login', component: LoginV2Component, data: { title: 'Login' } },
	{ path: 'receipt/:feature', component: ReceiptComponent, data: { title: 'Receipt' } },
	{ path: 'order-receipt/:order', component: OrderReceiptComponent, data: { title: 'Order Receipt' } },
	{ path: 'reset-password/:authorLink/:resetCode', component: ForgotPasswordComponent, data: { title: 'Reset Password' } },
	{ path: 'signup', component: SignupV2Component, data: { title: 'Signup' } },
	{ path: 'support', component: SupportComponent, data: { title: 'Support' } },
	{ path: 'terms', component: TermsComponent, data: { title: 'Terms of Service' } },
	{ path: 'confirm-auth', component: ConfirmAuthComponent, data: { title: 'Auth Confirmation' } },
	{ path: '**', redirectTo: '/features' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {

}
