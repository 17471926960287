import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CoreModule } from '../../core.module';
import { UserService } from './user.service';
import { FeaturedBooksService } from './featured-books.service';
import { SitesService } from './sites.service';
import { LocalStorageService } from './local-storage.service';
import { PromoStackService } from './promo-stack.service';
import { SiteAdvertsService } from './site-adverts.service';
import { UrlQueryService } from '../url-query.service';

import { PromoStack } from '../../../types/promo-stacks';
import { Book } from '../../../types/book.interface';

import { ISiteAdvert } from '../../../types/datatypes';

@Injectable({
	providedIn: CoreModule,
})

export class PromoStackPurchaseService {
	public currentStep = {
		step: 1,
		name: 'Select Promo Stack',
	};

	public purchaserInfo = {
		firstName: '',
		lastName: '',
		email: '',
		memberRole: '',
		loggedIn: false,
	};

	public siteAdverts: ISiteAdvert[];

	public kindleCountdownDeal = false;

	public kindleUnlimited = false;

	public bookPromoPrice = 0;

	public description = '';

	public selectedDateSet;

	// public promoStackId;
	public orderPromoStackId;

	public releaseSchedule;

	public subscribeToList = false;

	public authorLink: string;

	public availability;

	public referrer: string;

	private _promoStack: PromoStack;

	private _book: Book;

	private _startDate: Date;

	private _steps = [
		{ step: 1, name: 'Select Promo Stack' },
		{ step: 2, name: 'Select Date' },
		{ step: 3, name: 'Book Info' },
		{ step: 4, name: 'Purchase Details' },
	];

	private paramSub: any;

	private siteAdvertSub: any;

	private _loading = new Subject<boolean>();

	constructor(
		private featureService: FeaturedBooksService,
		private userService: UserService,
		private localStorageService: LocalStorageService,
		private sitesService: SitesService,
		private siteAdvertsService: SiteAdvertsService,
		private promoStackService: PromoStackService,
		private urlQueryService: UrlQueryService,
	) {
		this.userService.fetchUser().catch(() => {});
		this.currentStep = this._getStep();
		if (this.userService.me) {
			this.purchaserInfo = {
				firstName: this.userService.me.firstName,
				lastName: this.userService.me.lastName,
				email: this.userService.me.username,
				memberRole: this.userService.getMemberRoleId(),
				loggedIn: this.userService.loggedIn,
			};

			this.persistPurchaserInfo();
		}

		this.siteAdvertSub = this.siteAdvertsService.siteAdvertsStore$?.subscribe((siteAdverts: ISiteAdvert[]) => {
			this.siteAdverts = siteAdverts;
			this.siteAdvertSub?.unsubscribe();
		});
	}

	get loading$() {
		return this._loading.asObservable();
	}

	get promoStack() {
		return this._promoStack;
	}

	get book() {
		return this._book;
	}

	get promoStackNameKabob() {
		if (!this._promoStack) return '';
		return this._promoStack.name.toLowerCase().replace(/ /g, '-');
	}

	get promoStackStartDate() {
		if (!this._startDate) return '';
		return moment(this._startDate).format('MMMM Do, YYYY');
	}

	get promoStackStartDateTimestamp() {
		if (!this._startDate) return '';
		return moment(this._startDate).format('YYYYMMDD');
	}

	get steps() {
		return this._steps;
	}

	processUrlParams() {
		const { referrer } = this.urlQueryService.load();

		if (referrer) {
			this.referrer = referrer;
		}
	}

	public setAuthorLink({ authorLink }) {
		this.authorLink = authorLink;
	}

	public reset() {
		this.resetSteps();
		this._promoStack = null;
		this._book = null;
		this._startDate = null;
		this.orderPromoStackId = null;
	}

	public checkIsAuthorStoreEnabled(): boolean {
		for (const siteAdvert of this._promoStack.siteAdverts) {
			const site = this.sitesService.getSiteById(siteAdvert._site);
			if (site?.siteDevices?.includes('Author Store')) {
				return true;
			}
		}

		return false;
	}

	public setState({ promoStackName, promoStackDate }, isCheckout) {
		this._loading.next(true);
		this.processUrlParams();

		if (!promoStackName) {
			this.init(null, null, null, isCheckout);
			return;
		}

		this.paramSub = this.promoStackService.promoStackStore$.subscribe((d) => {
			const _promoStackName = decodeURIComponent(promoStackName);
			const promoStack = this.promoStackService.getPromoStackByNameKabob(_promoStackName);
			const book = this.localStorageService.getJSONData('promoStackBook');

			if (!promoStack) {
				this.paramSub?.unsubscribe();
				return this.init(null, null, null, isCheckout);
			}

			return this.promoStackService.getPromoStackAvailability(promoStack._id)
				.pipe(first())
				.subscribe((promoStackAvailability: any) => {
					this.availability = promoStackAvailability;
					const _availability = promoStackAvailability
						.find((item) => {
							const [firstItem] = item.sort((a, b) => a.pubDate - b.pubDate);
							return firstItem.pubDate.toString() === promoStackDate;
						});

					if (!_availability) {
						this.paramSub?.unsubscribe();
						return this.init(promoStack, null, null, isCheckout);
					}
					this.paramSub?.unsubscribe();
					this.selectDate(promoStackDate, _availability);
					return this.init(promoStack, promoStackDate, book, isCheckout);
				});
		});
	}

	public getLink() {
		return `/promo-stacks/${this.promoStackNameKabob}/${this.promoStackStartDateTimestamp}`;
	}

	public getEditDateLink() {
		return `/promo-stacks/${this.promoStackNameKabob}/`;
	}

	public editPromoStack(promoStack) {
		this._loading.next(true);
		const { _id, _book, _promoStack, startDate, featureReleaseSchedule, _features } = promoStack;
		const [feature] = _features;
		_book.description = feature.description;
		this.kindleCountdownDeal = feature.kindleCountdownDeal;
		this.kindleUnlimited = feature.kindleUnlimited;
		this.bookPromoPrice = +feature.bookPriceDuringPromo;
		this.purchaserInfo = feature.purchaserInfo;
		this.orderPromoStackId = _id;
		// this.selectedDateSet = featureReleaseSchedule;
		// This needs to exist because the startDate in the DB is a rational date. But the pubdate is irrational.
		// So we need to get the first pubdate and use that as the start date.
		const irrationalStartDate = this.getIrrationalStartDate(featureReleaseSchedule);
		this._startDate = moment(irrationalStartDate, 'YYYYMMDD').toDate();
		this.init(_promoStack, startDate, _book);
		this.releaseSchedule = featureReleaseSchedule;
	}

	public getIrrationalStartDate(featureReleaseSchedule) {
		const [firstRelease] = featureReleaseSchedule.sort((a, b) => a.pubDate - b.pubDate);
		return firstRelease.pubDate;
	}

	public getFeatureDetails() {
		const { bookPromoPrice, kindleCountdownDeal, kindleUnlimited, purchaserInfo } = this;
		const { ASIN, description, whereAvailable } = this.book;
		return {
			ASIN,
			bookPromoPrice,
			description,
			kindleCountdownDeal,
			kindleUnlimited,
			purchaserInfo,
			purchaseSource: 'Promo Stacks',
			whereAvailable,
		};
	}

	public setAdditionalAttributes(attrs) {
		this.kindleCountdownDeal = attrs.kindleCountdownDeal;
		this.kindleUnlimited = attrs.kindleUnlimited;
		this.bookPromoPrice = attrs.promo_price;
		this.purchaserInfo.email = attrs.purchaserInfoEmail;
		this.purchaserInfo.firstName = attrs.purchaserInfoFirstName;
		this.purchaserInfo.lastName = attrs.purchaserInfoLastName;
		this.purchaserInfo.memberRole = this.userService.getMemberRoleId();
		if (!this.purchaserInfo.memberRole) {
			delete this.purchaserInfo.memberRole;
		}
		this.purchaserInfo.loggedIn = this.userService.loggedIn;
		this.persistPurchaserInfo();
		this.persistFeatureDetails();
		this.description = attrs.description;
		this.subscribeToList = attrs.subscribeToList;
	}

	public selectDate(date: Date, selectedDateSet) {
		this.selectedDateSet = selectedDateSet;
		this._startDate = date;

		this.releaseSchedule = this.selectedDateSet.map((item, i) => {
			const siteAdvert = this.siteAdverts.find((advert) => advert._id === item.siteAdvertId);
			const _date = moment(this.selectedDateSet[i].pubDate, 'YYYYMMDD').format('MMM. D');
			const site = this.sitesService.getSiteById(siteAdvert._site);

			return {
				name: site.name,
				advert: siteAdvert.name,
				siteAdvertId: siteAdvert._id,
				date: _date,
				pubDate: item.pubDate,
			};
		}).sort((a, b) => a.pubDate - b.pubDate);
	}

	public selectBundle(promoStack: PromoStack) {
		this._promoStack = promoStack;
	}

	public setBookInfo(book) {
		this.setBook(book);
	}

	public setBook(book) {
		this._book = book;
		this.persistBookInfo();
	}

	public recallBookInfo() {
		const book = this.localStorageService.getJSONData('promoStackBook');
		if (book) {
			this._book = book;
		}
	}

	public recallPurchaserInfo() {
		const purchaserInfo = this.localStorageService.getJSONData('promoStackPurchaser');
		if (purchaserInfo) {
			this.purchaserInfo = purchaserInfo;
		}
	}

	public recallFeatureDetails() {
		const details = this.localStorageService.getJSONData('promoStackFeatureDetails');
		if (details) {
			this.kindleCountdownDeal = details.kindleCountdownDeal;
			this.kindleUnlimited = details.kindleUnlimited;
			this.bookPromoPrice = details.bookPromoPrice;
		}
	}

	public persistPurchaserInfo() {
		this.localStorageService.saveJSONData('promoStackPurchaser', this.purchaserInfo);
	}

	public persistBookInfo() {
		this.localStorageService.saveJSONData('promoStackBook', this._book);
	}

	public persistFeatureDetails() {
		const details = {
			kindleCountdownDeal: this.kindleCountdownDeal,
			kindleUnlimited: this.kindleUnlimited,
			bookPromoPrice: this.bookPromoPrice,
		};
		this.localStorageService.saveJSONData('promoStackFeatureDetails', details);
	}

	public abandonBookInfo() {
		this.localStorageService.removeData('promoStackBook');
	}

	public abandonPurchaserInfo() {
		this.localStorageService.removeData('promoStackPurchaser');
	}

	public abandonFeatureDetails() {
		this.localStorageService.removeData('promoStackFeatureDetails');
	}

	public init(promoStack: PromoStack, timeslot?, book?, checkingOut?) {
		this._promoStack = promoStack;
		this._book = book;

		if (!this._promoStack) {
			return this._setCurrentStep(1);
		}

		if (!this._startDate) {
			return this._setCurrentStep(2);
		}

		if (checkingOut) {
			return this._setCurrentStep(4);
		}

		return this._setCurrentStep(3);
	}

	public previousStep() {
		this._setCurrentStep(this.currentStep.step - 1);
	}

	public resetSteps() {
		this._setCurrentStep(1);
	}

	private _setCurrentStep(stepNumber = 1) {
		this.currentStep = this._getStep(stepNumber);
		this._loading.next(false);
	}

	private _getStep(stepNumber = 1) {
		return this._steps.find(({ step }) => step === stepNumber);
	}

	private _nextStep() {
		this._setCurrentStep(this.currentStep.step + 1);
	}

	private _kabobCase(str: string) {
		return str.toLowerCase().replace(/ /g, '-');
	}
}
