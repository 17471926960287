<div class="form-group" [formGroup]="form">
    <label
        *ngIf="label"
        [for]="id"
        class="form-label"
    >
        {{ label }} 
        <span class="required" *ngIf="required">*</span>
    </label><app-tooltip-card *ngIf="tooltipText" [label]="tooltipLabel" [text]="tooltipText"></app-tooltip-card>
    <img class="form-icon" *ngIf="icon" [src]="icon" [alt]="icon">
    <input 
        [id]="id"
        [value]="value"
        [type]="type"
        [formControlName]="id"
        [disabled]="disabled"
        [readonly]="readonly"
        class="form-control"
        [class]="{ 'readonly': readonly || disabled, 'form-icon-left': icon }"
        (click)="clickEvent()"
        [min]="min"
        (change)="preventOutOfRange($event)"
        [placeholder]="placeholder"
    >
    <div class="form-helper" *ngIf="helper">{{ helper }}</div>
    <button class="input-button" *ngIf="buttonText" (click)="buttonClickEvent()">{{buttonText}}</button>
</div>
