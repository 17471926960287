<section>
    <div class="content">
        <div class="alert" *ngIf="!siteAdvert">
            <p>Sorry - We couldn't find the following site advert <b>{{ siteAdvertId }}</b>.</p>
        </div>
        <div class="giveaway-box" *ngIf="siteAdvert">
            <div class="site-advert">{{ siteAdvert?.name }} eBook and Kindle Giveaway</div>
            <p class="description">One winner will be chosen at random on {{ endDate }} and notified via email 
                that they have won the eBooks Prize Package. Good luck!</p>
            <div *ngIf="!validEndDate">
                <p class="alert">This giveaway has been expired. <br>Last date: {{ endDate }}</p>
            </div>

            <div *ngIf="validEndDate">
                <div class="subtitle" [style]="{ 
                    'color': siteAdvert?.giveaway?.sourceSite?.primaryColor }">Use these 3 steps to enter to win</div>
                <div class="how-to-enter" 
                    [style]="{ 
                    'background-color': siteAdvert?.giveaway?.sourceSite?.secondaryColor + '40' }">
                    
                    <ul class="hte-steps">
                        <li>
                            <div class="hte-numbe-container">
                                <span class="hte-step-number" [style]="{ 
                                    'color': siteAdvert?.giveaway?.sourceSite?.primaryColor }">1</span> 
                            </div>
                            <div>
                                <span class="hte-step-title">Get Started: Enter your name and email address.</span>
                                <span class="hte-step-description">* We need this information to contact you if you win.</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <form (submit)="submit($event)" [formGroup]="form" *ngIf="!stored">
                    <app-input-form 
                    [id]="formComponents.name.key" 
                    [label]="formComponents.name.label"
                    [type]="formComponents.name.type" 
                    [required]="formComponents.name.required" 
                    [placeholder]="formComponents.name.placeholder" 
                    [form]="form"></app-input-form>
                    <app-input-form 
                    [id]="formComponents.email.key" 
                    [label]="formComponents.email.label"
                    [type]="formComponents.email.type" 
                    [required]="formComponents.email.required" 
                    [placeholder]="formComponents.email.placeholder" 
                    [form]="form"></app-input-form>
                        
                    <button class="btn block" type="submit" 
                    [style]="{ 
                        'background-color': siteAdvert?.giveaway?.sourceSite?.primaryColor, 
                        'border-color': siteAdvert?.giveaway?.sourceSite?.secondaryColor }">Submit and Select Newsletters</button>
                </form>
                <div *ngIf="stored">
                    <div class="stored-user">
                        <div class="user">{{ form.controls.email.value }}</div>
                    </div>
                </div>
                <div class="how-to-enter" 
                    [style]="{ 
                    'background-color': siteAdvert?.giveaway?.sourceSite?.secondaryColor + '40' }">
                    
                    <ul class="hte-steps">
                        <li>
                            <div class="hte-numbe-container">
                                <span class="hte-step-number" [style]="{ 
                                    'color': siteAdvert?.giveaway?.sourceSite?.primaryColor }">2</span> 
                            </div>
                            <div>
                                <span class="hte-step-title">Earn Entries: Get an entry for each author mailing list you subscribe to.</span>
                            </div>
                        </li>
                    </ul>
                    <div class="join-all-list">
                        <span class="join-all-list-title">Sign Me up for all Newsletters!</span>
                        <div>
                            <button class="btn btn-sm block mb-0 join-all-list-button" type="button"
                                (click)="joinAllLists()" 
                                [style]="{ 
                                    'background-color': siteAdvert?.giveaway?.sourceSite?.primaryColor, 
                                    'border-color': siteAdvert?.giveaway?.sourceSite?.secondaryColor 
                                }"
                                [ngClass]="{ 'disabled': !stored }">Opt-in to All (+{{ features.length + 1 }} Entries)</button>
                        </div>
                    </div>
                </div>
                <div class="sailthru-list">
                    <div class="sailthru-list-item" *ngFor="let feature of features">
                        <div class="sailthru-list-content">
                            <img [src]="feature._book.amazonImageUrl" class="list-icon" alt="">
                            <div class="list-name">Join {{ feature._book.author }} Author Newsletter</div>
                        </div>
                        <button class="join-button" 
                        [ngClass]="{ 'joined': joinedAuthors[feature._id], 'disabled': !stored }" 
                        [disabled]="!stored" (click)="joinAuthorsList(feature._id)" 
                        [style.background-color]="siteAdvert?.giveaway?.sourceSite?.primaryColor">
                            <span *ngIf="loading">...</span>
                            <div class="button-text" *ngIf="!loading">
                                {{ joinedAuthors[feature._id]? 'Entered' : '+1' }} 
                                <span class="d-flex" *ngIf="joinedAuthors[feature._id]">
                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                    width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 
                                        6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 
                                        7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z" 
                                        [attr.fill]="siteAdvert?.giveaway?.sourceSite?.primaryColor"/>
                                    </svg>
                                </span>
                            </div>
                        </button>
                    </div>
                </div>

                <div class="how-to-enter my-20" 
                    [style]="{ 
                    'background-color': siteAdvert?.giveaway?.sourceSite?.secondaryColor + '40' }">
                
                    <ul class="hte-steps">
                        <li>
                            <div class="hte-numbe-container">
                                <span class="hte-step-number" [style]="{ 
                                    'color': siteAdvert?.giveaway?.sourceSite?.primaryColor }">3</span> 
                            </div>
                            <div>
                                <span class="hte-step-title">Bonus Entries: Join the {{ siteAdvert?.giveaway?.sourceSite?.name }} Mailing List</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="sailthru-list">
                    <div class="sailthru-list-item">
                        <div class="sailthru-list-content">
                            <img [src]="sailthruImage" class="list-icon" alt="">
                            <div>
                                <div class="list-name">Join {{ siteAdvert?.giveaway?.sourceSite?.name }} Author Newsletter</div>
                            </div>
                        </div>
                        <button class="join-button" 
                        [ngClass]="{ 'joined': joined, 'disabled': !stored }" 
                        [disabled]="!stored" (click)="joinSailthruList()" 
                        [style.background-color]="siteAdvert?.giveaway?.sourceSite?.primaryColor">
                            <span *ngIf="loading">...</span>
                            <div class="button-text" *ngIf="!loading">
                                {{ joined? 'Entered' : '+1' }} 
                                <span class="d-flex" *ngIf="joined">
                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                    width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 
                                        6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 
                                        7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z" 
                                        [attr.fill]="siteAdvert?.giveaway?.sourceSite?.primaryColor"/>
                                    </svg>
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-error-modal
  [modalId]="'ac-error-modal'"
  [text]="errorText"></app-error-modal>
